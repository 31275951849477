@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$BACKGROUND_COLOR_NOT_ACTIVE: white;
$BACKGROUND_COLOR_ACTIVE: white;
$COLOR_NOT_ACTIVE: rgba(0,0,0,.5);
$COLOR_ACTIVE: rgba(0,0,0,.75);
$FIRST_ROW_BACKGROUND_COLOR: #F1F1F1;
$LOGO_TITLE_COLOR: #494949;
$FORM_BACKGROUND_COLOR: #F7F7F7;
$COMMON_TEXT_COLOR: #262626;
$COMMON_GREEN_COLOR: #008539;
$LINK_COLOR: #0056B3;
$P_FONT_SIZE: 1.125rem;
$MENU_TOP_MARGIN: 122px;

body {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    color: $COMMON_TEXT_COLOR;
    overflow-x: hidden;
}

.app-menu .btn,
.main-content-container .btn,
.app-menu .dropdown-menu,
.main-content-container .dropdown-menu,
.main-content-container .form-control {
  font-size: .8rem;
  font-weight: bold;
}


.app-menu .bg-secondary {
  background-color: white !important;
}
.app-menu .navbar-light .navbar-nav .nav-link {
    color: $COMMON_TEXT_COLOR;
    font-size: .8rem;
    font-weight: bold;
}
.app-menu .nav-item .btn-secondary {
  color: $COMMON_TEXT_COLOR;
  background-color: $BACKGROUND_COLOR_NOT_ACTIVE;
  border: none;
  padding-left: 2.5px;
  width: 100%;
  text-align: center;
}
.app-menu .nav-item .btn-secondary:focus,
.app-menu .btn-secondary:not(:disabled):not(.disabled).active,
.app-menu .show>.btn-secondary.dropdown-toggle,
.app-menu .btn-secondary:not(:disabled):not(.disabled).active:focus, 
.app-menu .btn-secondary:not(:disabled):not(.disabled):active:focus, 
.app-menu .show>.btn-secondary.dropdown-toggle:focus,
.app-menu .dropdown-item.active,
.app-menu .navbar-light .navbar-nav .nav-link.active,
.app-menu .navbar-light .navbar-nav .nav-link:focus {
  color: $COMMON_TEXT_COLOR;
  background-color: $BACKGROUND_COLOR_ACTIVE;
  border: none;
  box-shadow: none;
  font-weight: bold;
}
.app-menu .first-row-menu .nav-item .btn-secondary:hover,
.app-menu .first-row-menu .nav-item .btn-secondary:focus,
.app-menu .first-row-menu .btn-secondary:not(:disabled):not(.disabled).active,
.app-menu .first-row-menu .show>.btn-secondary.dropdown-toggle,
.app-menu .first-row-menu .btn-secondary:not(:disabled):not(.disabled).active:focus, 
.app-menu .first-row-menu .btn-secondary:not(:disabled):not(.disabled):active:focus, 
.app-menu .first-row-menu .show>.btn-secondary.dropdown-toggle:focus,
.app-menu .first-row-menu .navbar-light .navbar-nav .nav-link.active,
.app-menu .first-row-menu .nav-item .btn-secondary {
  background-color: $FIRST_ROW_BACKGROUND_COLOR;
}
.app-menu .dropdown-toggle::after {
    display: none;
}
.app-menu .btn {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 2.5px;
  padding-left: 2.5px;
  margin: 0 5px;
}
.app-menu .first-row-menu .btn.nav-link {
  padding-top: 2px;
}
.app-menu .user-icon {
  margin-top: 0;
  margin-left: 5px;
}
%font-icon-common-part {
  font-weight: bold;
  padding: 1px;
}
.app-menu .font-icon-min {
  @extend %font-icon-common-part;
  font-size: .7rem;
}
.app-menu .font-icon-med {
  @extend %font-icon-common-part;
  font-size: .9rem;
}
.app-menu .font-icon-max {
  @extend %font-icon-common-part;
  font-size: 1rem;
}
.app-menu .main-logo {
  width: 300px; 
  transition: all .3s ease;
}
.app-menu .logo-title {
  color: $LOGO_TITLE_COLOR;
}
.app-menu .first-row-menu .navbar {
  padding: 0 0;
}
.app-menu .first-row-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $FIRST_ROW_BACKGROUND_COLOR;
  z-index: 1040;
}
.app-menu .second-row-menu {
  padding: 8px 0;
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  background-color: $BACKGROUND_COLOR_NOT_ACTIVE;
  z-index: 1030;
  font-size: .8rem;
  font-weight: bold;
}
.btn:focus-visible:not(.dropdown-toggle:active), #filtr_prosty:focus-visible, .new-pagination .page-item .page-link:focus-visible{
    outline: $COMMON_TEXT_COLOR auto 1px; //draft, will be changed
}
.keyboard-menu{
  position: absolute;
  z-index: 2000;
  width: 100%;
}
.keyboard-menu .nav-item{
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  a{
    position: absolute;
    top:-1500px;
    margin: 0 auto;
    &:focus{  
      top:2px;
    }
  }
}
.home-container .title {
  font-size: 1.75rem;
  font-weight: bold;
}
.home-container .title2 {
  font-size: 1.75rem;
  color: $COMMON_GREEN_COLOR;
  font-weight: bold;
}
.home-container .title span {
  display: block;
}
.home-container .section.second .list {
  font-size: 1.125rem;
}
.home-container .numbers {
  text-align: center;
  font-weight: 800;
  font-size: .8rem;
  margin-bottom: 25px;
}
.app-menu .dropdown-item{
  text-transform: none;
}
.home-container .form-control,.home-container .form-control:focus {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #000;
  box-shadow: none;
}
.home-container label {
  font-size: .75rem;
}
.home-container .section {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  position: relative;
}
.home-container .search-form {
  position: sticky;
  position: -webkit-sticky;
  background-color: white;
  top: $MENU_TOP_MARGIN;
  box-shadow: 0 10px 10px -10px rgba(87, 97, 100, 0.35);
  z-index: 1026;
}
 .nav-socials.white-version {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
.home-container .section.second {
  background-image: url(/images/amu-slide-mod-1-c.jpg);
}
.home-container .section.third {
  background-color: black;
  background-image: url(/images/amu-slide-mod-2-c.jpg);
  .mobile-version {
    visibility: hidden;
    display: none!important;
  }
}
.home-container .section.fourth {
  background-image: url(/images/amu-slide-mod-3.jpg);
}
.home-container .see-more-button {
  background-color: $COMMON_GREEN_COLOR;
  display: inline-block;
  font-size: 1rem;
  padding: 20px 30px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-weight: bold;
  z-index: 1;
  border: 2px solid transparent;
  border-radius: 0;
  margin-bottom: 80px;
  outline: none;
  text-decoration: none;
  bottom: 0;
}
.biogis .see-more-button, .not-found .see-more-button {
  background-color: $COMMON_GREEN_COLOR;
  display: inline-block;
  font-size: 1rem;
  padding: 15px 30px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-weight: bold;
  border: 2px solid transparent;
  border-radius: 0;
  outline: none;
  text-decoration: none;
}
.home-container .see-more-button:hover,
.home-container .see-more-button:focus,
.biogis .see-more-button:hover,
.biogis .see-more-button:focus,
.not-found .see-more-button:hover,
.not-found .see-more-button:focus {
  background-color: #414141;
}
.home-container .see-more-button.btn-primary:not(:disabled):not(.disabled).active, 
.home-container .see-more-button.btn-primary:not(:disabled):not(.disabled):active, 
.home-container .see-more-button.show>.btn-primary.dropdown-toggle{
  box-shadow: none;
  background-color: $COMMON_GREEN_COLOR;
  border-color: $COMMON_GREEN_COLOR;
}
.home-container .mobile-icons {
  width: 40px;
  margin: 10px 30px 10px 20px;
  transition: all .3s ease;
}
.home-container .mobile-icons:hover {
  transform: scale(1.3, 1.3);
}
.scroll-menu {
  position: fixed;
  top: 0;
  z-index: 1030;
  left: 60px;
  height: 100vh;
//  padding-top: 200px; // set this dynamically if vertical centering must be done in respect to top menu margin
}
.scroll-menu .text {
  font-size: 1rem;
  font-weight: 700;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  line-height: 22px;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.scroll-menu .not-active .name,
.scroll-menu .active .dot {
  display: none;
}
.scroll-menu .dot {
  -webkit-transition: box-shadow 0.5s ease;
  transition: box-shadow 0.5s ease;
  width: 10px;
  height: 10px;
  display: inline-block;
  border: 1px solid;
  border-radius: 50%;
  position: relative;
  background-color: $COMMON_TEXT_COLOR;
  margin-bottom: 5px;
  padding: 0;
}
.scroll-menu.white-version .dot {
  background-color: white;
  border-color: white;
}
.scroll-menu .btn-link {
  padding: 2px;
  margin-bottom: -10px;
  color: $COMMON_TEXT_COLOR;
  white-space: nowrap;
}
.scroll-menu.white-version .btn-link {
  color: white;
}
.scroll-menu .text-hover {
  opacity: 0;
  transition: opacity 0.5s ease;
  padding-left: 15px;
  font-size: .6875rem;
  font-weight: 500;
  width: 80px;
  display: inline-block;
  text-align: left;
}
.scroll-menu .btn-link:hover {
  text-decoration: none;
}
.scroll-menu .not-active.btn-link:hover > *, .scroll-menu .not-active.btn-link:focus > *{
  opacity: 1;
}
.scroll-menu .active {
  cursor: default !important;
}
.scroll-menu .name {
  padding-bottom: 10px;
  padding-top: 5px;
}
/*Back To Top*/
.scroll-menu .back-top {
  color: #fff;
}
.back-top {
	left: 50px;
	position: fixed;
	z-index: 1600;
	opacity: 0;
	visibility: hidden;
	bottom: 40px;
	height: 40px;
	width: 40px;
	background: #313131;
	text-align: center;
	line-height: 38px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.back-top-visible {
  opacity:1;
  visibility: visible;
}
.home-container .clear-button {
  visibility: hidden;
  display: none;
}
.home-container .search-button,
.home-container .search-button.focus,
.home-container .search-button:active:focus,
.home-container .search-button.btn-primary:not(:disabled):not(.disabled).active:focus, 
.home-container .search-button.btn-primary:not(:disabled):not(.disabled):active:focus, 
.home-container .search-button.show>.btn-primary.dropdown-toggle:focus {
  background: url(/images/lupa.png) center center no-repeat;
  border: none;
  box-shadow: none;
  background-color: white;
  opacity: 0.6;
  height: 47px;
  width: 43px;
  background-size: contain;
  cursor: pointer;
  font-size: 0;
  margin-top: 10px;
}
.home-container .search-button:disabled {
  opacity: 0.3;
  cursor: default;
}
.home-container .section.first .icondb-openseadragon-container {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.home-container .section .content {
  width: 100%;
  position: relative;
  background-color: rgba(255,255,255,0.3);
}
.home-container .section .info {
  width: 100%;
  position: relative;
  text-align: center;
  font-weight: bold;
}
.home-container .section .inner {
  display: inline-block;
  background-color: white;
  padding: 20px;
  opacity: 0;
  transition: opacity 0.5s;
}
.home-container .slider-background {
    position: absolute;
    background-color: white;
    width: 100%;
    height: 100%;
    opacity: .4;
}
.home-container .slider-content {
    position: relative;
    margin: 0;
}
.home-container .slider-item {
  padding: 0 10px;
}
.home-container .slider-item .element {
  padding: 20px 0;
  margin: 0 5px;
}
.home-container .slider-item .element-info {
  margin-right: 10px;
}
.home-container .slider-content .circle img {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  height: 97px;
  transition: all .3s ease;
  border-color: white;
}
.home-container .slider-content .btn,
.home-container .slider-content .btn-link,
.home-container .slider-content .btn-link:hover {
  padding: 0;
  color: black;
  text-decoration: none;
  font-size: .7rem;
  text-align: left;
  font-weight: 600;
  border: none;
}
.home-container .slider-content .btn-link .slider-item-title {
  font-size: .9rem;
  font-weight: 600;
  padding-bottom: 15px;
}
.home-container .slider-content .btn-link:hover .circle img {
    transform: scale(.9, .9);
    border: solid 5px white;
}
.home-container .slider-content .btn-link:hover .slider-item-title,
.home-container .slider-content .btn-link:focus .slider-item-title {
  font-weight: 800;
  text-decoration: underline solid $COMMON_TEXT_COLOR;
}
.home-container .carousel-control-next,
.home-container .carousel-control-prev {
  width: 50px;
}
.home-container .carousel-control-next-icon,
.home-container .carousel-control-prev-icon {
  background-color: #313131;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 21px;
  background-size: 50%;
}
.home-container .carousel-control-prev {
  margin-left: -65px;
}
.home-container .carousel-control-next {
  margin-right: -65px;
}
.home-container .carousel.slide {
  width: 100%;
}
.about-portal{
  max-width: 80ch;
  li{
    font-size: $P_FONT_SIZE;
  }
  ul, p{
    max-width: 80ch;
    padding-bottom: 2.5rem;
    ul p, ul p:first-of-type, ul li{
      padding-top: 0;
      padding-bottom: 1.25rem;
    }
    ul{
      padding-bottom: 0;
    }
  }
  img{
    width: 90%;
  }
}

.contact-form {
  background-color:$FORM_BACKGROUND_COLOR;
}
.contact-form .title {
  font-size: 1.5rem;
  margin-top: 16px;
}
.contact-form .contact-container {
  background-color: #ededed;
  padding: 48px 16px;
  font-size: 1.125rem;
  .address {
    margin-top: 30px;
  }
}
.contact-form .main-form h1 {
  padding-bottom: 50px;
}
.contact-form .btn-primary {
  border-radius: 0;
  text-transform: uppercase;
  font-size: .8rem;
  padding: 16px 32px;
  font-weight: 600;
}
.contact-form .btn-default {
  border-radius: 0;
  font-size: .8rem;
  padding: 16px 32px;
  background-color: #ededed;
  margin-left: 32px;
  color: #000;
  font-weight: 600;
}
.contact-form #message {
  height: 328px;
}

.list-group-item img {
  width: 24px;
  margin-right: 10px;
}
.list-group .list-group-item {
  border-radius: 0;
  border-left-style: none;
  border-right-style: none;
}
.search-container .search-form {
  box-shadow: none;
}
.search-container .info {
  font-weight: bold;
  background-color:$FORM_BACKGROUND_COLOR;
  padding: 20px 0;
}
.search-container .info .line2 {
  font-size: 1.1rem;
}
.search-container .info .line3 {
  font-size: .7rem;
}
.search-container {
  min-height: 55vh;
}
.biogis img {
  width: 60%;
}
.separator-before {
  border-top: 1px solid #cecece;
}
.separator-after {
  border-bottom: 1px solid #cecece;
}
.nav-tabs .nav-link {
  background-color: $FORM_BACKGROUND_COLOR;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-tabs a {
  color: black;
}
.nav-tabs .nav-link.active {
  background-color: white;
  font-weight: 600;
}
.nav-tabs {
  border: none;
}
.nav  {
  -ms-flex-pack: center!important;
  justify-content: center!important;
  background-color: $FORM_BACKGROUND_COLOR;
}
.search-results {
  padding: 20px 20px;
  background-color: $FORM_BACKGROUND_COLOR;
  font-weight: 600;
}
.btn {
  border-radius: 0;
}
.new-pagination .dropdown {
  border: 1px solid black;
}
.new-pagination ul {
  background-color: $FORM_BACKGROUND_COLOR;
  margin-top: 10px;
  flex-wrap: wrap;
}
.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.new-pagination .page-item .page-link {
  border: none;
  color: black;
  background-color: $FORM_BACKGROUND_COLOR;
}
.new-pagination .page-item .page-link:focus {
  box-shadow: none;
}
.new-pagination .page-item .page-link:hover {
  background-color: $FIRST_ROW_BACKGROUND_COLOR;
}
.new-pagination .page-item.active .page-link {
  background-color: white;
  font-weight: 800;
}
.pagination-page-form{
  font-size: .875rem;
  padding-bottom: 40px;
  padding-top: 15px;
  background-color: $FORM_BACKGROUND_COLOR;
  display: flex;
  justify-content: center;
  align-items: center;
  .btn{
    font-size: .875rem;
  }
}
.pagination-input-group{
  display: flex;
  align-items: center;
  input, input.pagination-input{
    font-size: .875rem;
    height: unset;
    margin: 0 0.875rem;
  }
  label{
    text-align: center;
    margin: 0;
  }
  margin: 0;
  text-align: center;
}
.form-control {
  border: none;
  border-radius: 0;
  background-color: $FORM_BACKGROUND_COLOR;
  border-bottom: 1px solid #ced4da;
  height: calc(1.5em + 1.5rem + 2px);
}
.export-button .btn-primary, 
.export-button .btn-primary:not(:disabled):not(.disabled):active, 
.export-button .btn-primary:focus,
.export-button .show>.btn-primary.dropdown-toggle:focus,
.export-button .btn-primary:hover {
  background-color: white;
  border: none;
  color: black;
  box-shadow: none;
  background-image: url(/images/export.png);
  background-size: 25px;
  background-repeat: no-repeat;
}
.export-button .btn-primary:hover {
  background-color: $FIRST_ROW_BACKGROUND_COLOR;
}
.export-button.pl .btn-primary {
  padding-right: 60px;
  background-position: 170px;
} 
.export-button.en .btn-primary {
  padding-right: 60px;
  background-position: 140px;
} 
.popover .btn-link{
  text-align: left;
}
.info-icon-popover-container{
  display: inline;
}
.info-icon-popover-container .btn{
  font-size: 0.7rem;
  padding: 0.125rem 0 0;
}
.leaflet-popup-pane{
  font-family: 'Montserrat', sans-serif;
}
.leaflet-popup-pane .btn-link-map-popup{
  font-size: 12px;
  text-align: left;
}
.site-map-list{
  margin-top: 1rem;
}
.site-map-list li{
  margin-bottom: 0.30rem;
  margin-top: 0.35rem;
  &:first-of-type{
    margin-top: 0.25rem;
  }
  ul{
    margin-bottom: 0.50rem;
  }
  li{
    margin-bottom: 0.25rem;
    margin-top: 0.20rem;
  }
}
.not-found{
  min-height: 50vh;
  text-align: center;
  h1{
    padding-top: 0;
    font-size: 6rem;
  }
  h2{
    margin: 0;
  }
  p{
    padding: 0.25rem 0;
  }
  p:first-of-type{
    padding-top: 1.125rem;
  }
  p:last-of-type{
    padding-bottom: 1.125rem;
  }
  .see-more-button{
    margin: 0.5rem 0.875rem;
  }
}
.policy-container li {
  font-size: 1.125rem;
  .btn-link{
    padding: 0;
  }
}
@media (min-width: 576px) {
.app-menu .container {
    max-width: 100vw;
}
.container {
    max-width: 100vw;
}
.keyboard-menu .nav-item a:focus{
  top: 42px;
}
}
@media (min-width: 768px) {
.app-menu .container {
    max-width: 100vw;
}
.container {
    max-width: 100vw;
}
.keyboard-menu .nav-item{
    justify-content: start;
    width: 95%;
    a:focus{
      top: 2px;
    }
}
.not-found{
  text-align: left;
  .see-more-button{
    margin-left: 0;
    margin-right: 1rem;
  }
}
}
@media (min-width: 992px) {
.app-menu .container {
    max-width: 850px; //calc($MAX_REFERENCE_WIDTH - 3 * $COMMON_LEFT_RIGHT_MARGIN)px
}
.container {
    max-width: 770px; //calc($MAX_REFERENCE_WIDTH - 3 * $COMMON_LEFT_RIGHT_MARGIN)px
}
.home-container .nav-socials {
  bottom: 40px;
}
.nav-socials {
  position: fixed;
  bottom: 140px;
  right: 60px;
  z-index: 1234;
}
.popover{
  min-width: 276px;
}
.keyboard-menu{
  width: 50%;
}
.keyboard-menu .nav-item{
  width: 50%;
}
}
@media (min-width: 1200px) {
.app-menu .container {
    max-width: 1020px; //calc($MAX_REFERENCE_WIDTH - 2 * $COMMON_LEFT_RIGHT_MARGIN)px
}
.container {
    max-width: 940px; //calc($MAX_REFERENCE_WIDTH - 2 * $COMMON_LEFT_RIGHT_MARGIN)px
}
}
@media (min-width: 1400px) {
.app-menu .container {
    max-width: 1280px; //calc($MAX_REFERENCE_WIDTH - 2 * $COMMON_LEFT_RIGHT_MARGIN)px
}
.container {
    max-width: 1200px; //calc($MAX_REFERENCE_WIDTH - 2 * $COMMON_LEFT_RIGHT_MARGIN)px
}
}

.app-menu .second-row-menu .navbar {
  padding: 0;
}
.home-container .search-form .form-control {
  font-size: 1.2rem;
  font-weight: 600;
  padding-left: 0;
}
.home-container .section .container {
  height: 100%;
}
.home-container .section .row {
  height: 100%;
}
.home-container .section .section-content {
  position: relative;
  padding-top: 125px;
  height: 100%;
  bottom: 0;
  font-size: 1.125rem;
}
.home-container .section.fourth .section-content {
  padding-top: 0;
}
.home-container .section .right-col .section-container {
  position: relative;
  height: 100%;
}
.home-container .section .section-content .buttons {
  margin-bottom: 80px;
  bottom: 0;
}
.home-container .section .section-content .buttons .btn {
  padding: 0;
  border: none;
}
.home-container .section .popover-header{
  color: $COMMON_TEXT_COLOR;
}
.home-container .store-icons {
  width: 150px;
  margin-right: 20px;
  border: none;
}
.home-container .section .right-col {
  background-color: rgba(0,0,0,.2);
}
.home-container .section.first .title {
  padding-top: 40px;
}
.home-container .section.third .section-content {
  color: white;
}
.home-container .section.fourth .see-more-button,
.home-container .section.third .see-more-button {
  background-color: white;
  color: $COMMON_TEXT_COLOR;
  right: 0;
}
.home-container .section.fourth .see-more-button:hover,
.home-container .section.third .see-more-button:hover,
.home-container .section.fourth .see-more-button:focus,
.home-container .section.third .see-more-button:focus  {
  background-color: #414141;
  color: white;
  border-color: transparent;
}
.home-container .section .section-content .content-background {
  padding: 20px;
  background-color: rgba(255,255,255,.8);
}
.home-container .list-dot {
  padding: 10px;
  background-color: $COMMON_GREEN_COLOR;
  border-radius: 50%;
  margin-right: 20px;
}
.home-container .list-content {
  margin: 5px 0;
}
.home-container .list-content > div {
  padding: 10px 0;
}
.home-container .section.fourth .list-dot {
  background-color: #106ebc;
}
.navbar a.passive-header {
  cursor: default;
  text-transform: uppercase;
}
.with-passive-headers a.dropdown-item {
    background-color: white;
    padding-left: 24px;
}
.passive-header.dropdown-item,
.with-passive-headers a.dropdown-item:hover,
.navbar a.passive-header {
    background-color: #f8f9fa;
}
.with-passive-headers a.dropdown-item.indented {
    padding-left: 48px;
}
p {
  font-size: $P_FONT_SIZE;
  margin: 0;
  padding-bottom: 2.5rem;
  max-width: 80ch;
}
.container{
  p, h1, h2, h3, h4, h5, li{
    max-width: 80ch;
  }
  li{
    font-size: $P_FONT_SIZE;
  }
}
h1 {
  padding-top: 2.5rem;
  margin: 0;
}
.container h1{
  padding-top: 2.75rem;
  padding-bottom: 1.5rem;
}
.container p:first-of-type{
  padding-top: 1.5rem;
}
h2 {
  font-size: 1.75rem;
}
p.last-par {
  padding: 25px 0 80px 0;
}
.mobile-app-page .store-icons {
  width: 130px;
}
.mobile-app-page .store > div {
  padding: 20px;
}
.mobile-app-page .store {
  padding-bottom: 100px;
}
.mobile-app-page .store .btn {
  padding: 0;
}
.mobile-app-page .store > div:first-child {
  border-right: solid 1px $COMMON_TEXT_COLOR;
}
.mobile-app-page .store > div:last-child {
  border-left: solid 1px $COMMON_TEXT_COLOR;
}
// mobile app slider
.mobile-app-page .slider-content, .how-to-use .slider-content{
  background-color: $FIRST_ROW_BACKGROUND_COLOR;
  padding: 25px 0;
  margin: 25px 0 0 0;
}
.mobile-app-page .slider-item {
  padding: 0 160px;
}
.mobile-app-page .slider-item .text-container {
  padding: 40px;
}
.mobile-app-page .slider-item .text-container .desc{
  padding-top: 25px;
}
.mobile-app-page .slider-content img {
  height: 400px;
  padding: 20px;
  background-color: white;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}
.mobile-app-page .slider-content .btn,
.mobile-app-page .slider-content .btn-link,
.mobile-app-page .slider-content .btn-link:hover {
  padding: 0;
  color: black;
  text-decoration: none;
  font-size: .75rem;
  text-align: left;
  font-weight: 600;
  border: none;
}
.mobile-app-page .carousel-control-next,
.mobile-app-page .carousel-control-prev {
  width: 40px;
  padding: 0 60px;
}
.mobile-app-page .carousel-control-next-icon,
.mobile-app-page .carousel-control-prev-icon,
.how-to-use .carousel-control-next-icon,
.how-to-use .carousel-control-prev-icon {
  background-color: #313131;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 20px;
  background-size: 50%;
}
.mobile-app-page .carousel.slide,
.how-to-use .carousel.slide {
  width: 100%;
}
// /mobile app slider
.mobile-app-page .mobile-table {
  margin: 50px 20px 0 20px;
}
.mobile-app-page .mobile-table .item {
  padding: 40px;
  text-align: left;
  height: 200px;
}
.mobile-app-page .mobile-table img {
  max-height: 100%;
}
.mobile-app-page .mobile-table .item .txt {
  padding-left: 20px;
}
.mobile-app-page .col-xl-4,
.mobile-app-page .col-lg-6 {
  padding: 0;
}
.mobile-app-page .mobile-table .item-1 {
  border-bottom: solid 1px $COMMON_TEXT_COLOR;
}
.mobile-app-page .mobile-table .item-2 {
  border-bottom: solid 1px $COMMON_TEXT_COLOR;
}
.mobile-app-page .mobile-table .item-3 {
  border-bottom: solid 1px $COMMON_TEXT_COLOR;
}
.mobile-app-page .mobile-table .item-4 {
  border-bottom: solid 1px $COMMON_TEXT_COLOR;
}
@media (min-width: 992px) {
  .mobile-app-page .mobile-table .item-1 {
    border-right: solid 1px $COMMON_TEXT_COLOR;
  }
  .mobile-app-page .mobile-table .item-3 {
    border-right: solid 1px $COMMON_TEXT_COLOR;
  }
  .mobile-app-page .mobile-table .item-4 {
    border-bottom: solid 1px $COMMON_TEXT_COLOR;
  }
  .mobile-app-page .mobile-table .item-5 {
    border-right: solid 1px $COMMON_TEXT_COLOR;
  }
}
@media (min-width: 1200px) {
  .mobile-app-page .mobile-table .item-2 {
    border-right: solid 1px $COMMON_TEXT_COLOR;
  }
  .mobile-app-page .mobile-table .item-3 {
    border-right: none;
  }
  .mobile-app-page .mobile-table .item-4 {
    border-right: solid 1px $COMMON_TEXT_COLOR;
    border-bottom: none;
  }
}
@media (max-width: 992px) {
  .scroll-menu {
    visibility: hidden;
    display: none!important;
  }
}
@media (max-width: 768px) {
  .home-container {
    .section.third {
      background-position: 20%;
    }
    .section.second {
      .title2, 
      .list {
        width: 100%!important;
      }
    }
    .nav-socials,
    .search-form.notscrolled,
    .icondb-openseadragon-container,
    .section.first .title,
    .carousel-control-prev,
    .carousel-control-next,
    .slider-background,
    .zoom-container,
    .info {
      visibility: hidden;
      display: none!important;
    }
    .slider-item {
      flex-direction: column!important;
      .element {
        button {
          width: 100%;
        }
        .element-container {
          -ms-flex-pack: justify!important;
          justify-content: space-between!important;
        }
      }
    }
    .numbers {
      margin-bottom: 0;
      margin-top: 25px;
    }
    .section.first,
    .section.second,
    .section.fourth,
    .section.fifth {
      background-image: none;
      background-color: $FIRST_ROW_BACKGROUND_COLOR;
    }
    .section {
      .section-content {
        padding-top: 25px;
      }
    }
    .see-more-button-container {
      -ms-flex-pack: center !important;
      justify-content: center!important;
    }
    .see-more-button {
      position: relative;
    }
    .section.third {
      .normal-version {
        visibility: hidden;
        display: none!important;
      }
      .mobile-version {
        visibility: visible;
        display: -ms-flexbox!important;
        display: flex!important;
        height: 100%;
        color: white;
        > div {
          background-color: rgba(0, 0, 0, 0.5);
          padding: 25px;
        }
        .header {
          background-color: rgba(0, 0, 0, 0.3);
          height: auto;
        }
      }
    }
    .section.fourth {
      .content-background {
        background-color: transparent;
      }
      .section-content {
        padding-top: 20px;
      }
    }
    .slider-content img {
      height: 20vh;
      margin-bottom: 20px;
    }
    .slider-content {
      margin: 0;
    }
  }
  .home-container.search-container {
    .search-form, .info  {
      visibility: visible;
      display: block!important;
    }
  }
}
.about-us-page {
  h2 {
    padding-top: 40px;
  }
  li{
    font-size: $P_FONT_SIZE;
    padding-bottom: 1.5rem;
  }
  ul p, ul p:first-of-type{
    padding-top: 0;
    padding-bottom: 1.5rem;
  }

}

h1, h2 {
  font-weight: bold;
}

#main-menu2 {
  height: 40px;
}

.citation-tab.nav-link,
.citation-tab.nav-link.active {
  background-image: url(/images/1-ico-cytat.png);
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: auto 65%;
  background-position: center 2px;
  font-weight: bold;
  padding: 35px 3px 0 3px;
  font-size: .55rem;
  position: absolute;
  color: transparent;
  transition: all .3s ease;
  margin-right: 75px;
  margin-left: auto;
  right: 0;
  margin-top:-8px;
}
.nav.citation-tab-pulled-right {
  justify-content: left!important;
  position: relative;
}
.citation-tab.nav-link:hover,
.citation-tab.nav-link.active:hover,
.citation-tab.nav-link:focus,
.citation-tab.nav-link.active:focus {
  color: $COMMON_TEXT_COLOR;
}
.share-button {
  position: absolute;
  right: 0;
}
.share-button button.btn:focus,
.share-button .btn-light:not(:disabled):not(.disabled):active,
.share-button .btn-light:focus,
.share-button .btn-light:not(:disabled):not(.disabled):active,
.share-button.show > .btn-light.dropdown-toggle,
.share-button button.btn,
.share-button.nav-link{
  background-image: url(/images/3-ico-link.png);
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: auto 65%;
  background-position: center 2px;
  font-weight: bold;
  padding: 35px 3px 0 3px;
  font-size: .55rem;
  color: transparent;
  transition: all .3s ease;
  margin-right: 5px;
  margin-left: auto;
  border-color: transparent;
  box-shadow: none;
  z-index: 1;
  border: none;
  margin-top:-8px;
}
.share-button .btn-light.dropdown-toggle:focus,
.share-button .btn-light:not(:disabled):not(.disabled):active,
.share-button.show > .btn-light.dropdown-toggle,
.share-button button.btn:hover,
.share-button.nav-link:focus, 
.share-button.nav-link:hover {
  color: $COMMON_TEXT_COLOR;
  box-shadow: none;
}
.share-button button.btn::after {
  content: none;
}
.share-container {
  position: relative;
}
.share-container-icon-db{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.share-container .dropdown-menu{
  left: auto!important;
  right: -150px!important;
}
.citation-tab-pulled-right {
  padding-right: 120px;
}
.icon-container {
  .icon-content {
    min-height: 250px;
  }
  .share-container .dropdown-menu{
    right: -300px!important;
  }
}
.biblio-cite-button {
  width: 100%;
  text-align: left;
}
.biblio-cite-row {
  white-space: nowrap;
  border-bottom: 1px solid rgb(222, 226, 230);
}

.main-footer {
  padding-top: 20px;
  background-color: white;
}
.navbar-collapse.collapse.show .nav-item .btn-secondary,
.navbar-collapse.collapsing .nav-item .btn-secondary {
  text-align: left;
  padding: .5rem 0;
  margin: 0;
}
.main-logo-2 {
  margin-right: 15px;
}
.home-container {
  .search-form.scrolled {
    height: none;
    top: 0;
    .navbar {
      background-color: white;
      padding: 10px;
    }
  }
  .zoom-icon {
    padding: 0;
    margin: 5px 0;
    line-height: 0;
    height: 35px;
    width: 35px;
    background-color: rgba(255,255,255,.5);
    border-radius: 5px;
    font-size: 35px;
    color: black;
    font-weight: 100;
  }
  .zoom-icon:hover, .zoom-icon:focus {
    text-decoration: none;
  }
  .zoom-container {
    position: absolute;
    padding-top: 20px;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1025;
  }
}
@media (min-width: 768px) {
  .home-container .slider-item {
    justify-content: space-between !important;
  }
}
@media (min-width: 1310px) {
  .home-container .slider-item {
    justify-content: space-between !important;
  }
}
.home-container .section.fifth {
  background-color: #f7f7f7;
}
.nav.nav-tabs.nav-tabs-search {
  a {
    padding-top: 15px;
  }
  padding-top: 60px;
}
.nav-tabs-padding-top{
  padding-top: 60px;
}
.search-button, .clear-button {
  text-transform: uppercase;
}
.my-amunatcoll-search-form{
  .col-sm-9{
    width: 75%;
  }
  .col-sm-3{
    width: 25%;
  }
  .search-button{
    background: url(/images/lupa.png) center center no-repeat;
    border: none;
    box-shadow: none;
    background-color: white;
    opacity: 0.6;
    height: 47px;
    width: 43px;
    background-size: contain;
    cursor: pointer;
    font-size: 0;
      &:disabled {
        opacity: 0.3;
        cursor: default;
      }
      &:hover:not(:disabled), &:focus:not(:disabled){
        opacity: 1;
      } 
  }

  .form-label{
    font-size: .75rem;
  }
} 

.contact-form {
  .form-control {
    background-color: white;
    border: 1px solid #ced4da;
  }
  .address-container {
    padding-bottom: 100px;
  }
}
.home-container .slider-content .element-info img  {
  height: 18px;
}
.home-container .slider-content .element-info .first-img  {
  margin: 3px 6px 7px 3px;
}
.home-container .slider-content .element-info .second-img  {
  margin: 3px 3px 7px 0;
}
.user-space-wrapper{
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
  align-content: center;
  .chart-wrapper{
    width: 100%;
    height: 11.25vh;
  }
  p{
    padding-top: 0;
  }
}
@media (min-width: 768px) {
  .user-space-wrapper{
    flex-direction: row;
    justify-content: space-between;
    .chart-wrapper{
      width: 65%;
    }
  }
  }
@media (max-width: 1400px) {
.citation-tab.nav-link{
  margin-right: 60px;
}
}
.navbar-toggler {
  margin-right: 10px;
}
.navbar-toggler.collapsed {
  margin-top: 0;
}
.ue-text {
  font-size: 0.8rem;
}
.ue-flag {
  margin-left: 5px;
}
.toggler-container {
  padding-top: 6px;
}
.toggler-container.exp {
  padding-top: 5px;
  transition: all 0.0s step-end .31s;
}
.home-toggler-container {
  padding-top: 13px;
}
.home-toggler-container.exp {
  padding-top: 13px;
  transition: all 0.0s step-end .31s;
}
.home-eu-flag {
  margin-top: 19px;
}
.home-logo {
  margin-top: 24px;
}
.navbar-collapse.collapse.show .first-row-menu-collapsed .nav-item .btn-secondary,
.navbar-collapse.collapsing .first-row-menu-collapsed .nav-item .btn-secondary,
.navbar-collapse.collapse.show .first-row-menu-scrolled .nav-item .btn-secondary,
.navbar-collapse.collapsing .first-row-menu-scrolled .nav-item .btn-secondary {
  padding: .25rem 1.5rem;
}
@media (min-width: 576px) {
  .first-row-menu-collapsed {
    visibility: hidden;
    display: none!important;
  }
}
@media (max-width: 576px) {
 .app-menu .second-row-menu {
   top: 0;
  }
  .main-content-container {
    margin-top: 76px;
  }
}
@media (min-width: 680px) {
  .main-logo-2 {
    visibility: hidden;
    display: none!important;
  }
  .search-form.scrolled .main-logo-2 {
    visibility: visible;
    display: block!important;
  }
  .main-menu-scrolled {
    visibility: hidden;
    display: none!important;
  }  
}
@media (max-width: 680px) {
  .main-logo {
    visibility: hidden;
    display: none!important;
  }
  .section.second .title2,
  .section.third .title,
  .section.fourth .title {
    font-size: 1.25rem;
  }
  .search-form.scrolled {
    visibility: hidden;
    display: none!important;
  }  
}
@media (max-width: 768px) {
  .section.first .numbers-container {
    justify-content: flex-start!important;
  }
  .section.second .see-more-button-container > div {
    justify-content: center!important;
    align-items: flex-start!important;
    margin-top: 30px;
  }
}
.home-container .section.first { 
  h1 {
    font-size: 1.3rem;
    font-weight: 600;
    text-shadow: 2px 2px 2px white;
    .main {
      font-weight: 800;
    }
  }
  .container h1 {
    padding-top: 0;
  }
}
.search-form-info-icon {
  width: 18px;
}
.search-form-info-icon:hover img {
  opacity: .8;
}
.app-menu .btn.aaa-button {
  padding-top: 0;
  margin-top: -1px;
}
.p-font-size{
  font-size: $P_FONT_SIZE !important;
}
.circle-picker div:focus{
  transform: scale(1.2) !important; 
}
.how-to-use .slider-content img{
  justify-self: center;
  width: 90%;
  padding: 20px;
  background-color: white;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  object-fit: contain;
}
.how-to-use .slider-img-container{
  width: 100%;
  margin-bottom: 2.5rem;
}
.how-to-use .text-container{
  align-self: center;
  width: 80%;
}
.how-to-use .slider-item{
  flex-direction: column;
  width: 90%;
  margin: 0 5%;
}
.how-to-use .carousel-indicators{
  position: relative;
  margin-top: 0;
  margin-bottom: 20px;
  flex-wrap: wrap;
  li{
    background-color: $LOGO_TITLE_COLOR;
    border: solid 1px $LOGO_TITLE_COLOR;
    margin-right: 7px;
    margin-left: 7px;
    margin-top: 7px;
    width: 11px;
    height: 11px;
    padding: 0;
    border-radius: 10px;
  }
  li.active{
    background-color: $COMMON_TEXT_COLOR;
    border: solid 1px $COMMON_TEXT_COLOR;

  }
}
.how-to-use .carousel-control-next,
.how-to-use .carousel-control-prev {
  display: block;
  top: 250px;
  width: auto;
  margin: 0 0.5rem;
}
/* 
#####################
UTF-8 characters used
To check if they are commonly supported
*/
.how-to-use .effect::before{
  content: '◼ ';
}
.how-to-use .comment::before{
  content: '● ';
}
.how-to-use .comment-list li{
  list-style-type: '• ';
  font-size: 1rem;
}
/*
#####################
*/
@media (min-width: 1300px) {
  .how-to-use .slider-item{
    flex-direction: row;
  }
  .how-to-use .slider-img-container{
    width: 60%;
  }
  .how-to-use .text-container{
    width: 35%;
  }
}
.cursor-pointer{
  cursor: pointer;
}
.main-menu-collapsed.show,
.main-menu-collapsed.collapsing {
  top: 100%;
  left: -140px;
  position: absolute;
}
.main-menu-collapsed.show .navbar-nav,
.main-menu-collapsed.collapsing .navbar-nav {
  padding: .5rem 1rem;
  background-color: white;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
}

.home-container .section {
  min-height: calc(100vh - 98px);
}
.home-container .section.first {
  min-height: calc(100vh - 220px);
}
@media (max-width: 768px) {
  .home-container .section.first {
    min-height: calc(100vh - 122px);
  }
}
@media (max-width: 576px) {
  .home-container .section {
    min-height: calc(100vh - 66px);
  }
  .home-container .section.first {
    min-height: calc(100vh - 76px);
  }
}
.how-to-use .slider-content img.closed-icon-img{
  padding: 0;
  margin-bottom: 2px;
  width: 20px;
  height: 20px;
}

//MULTIBROWSER DATEPICKER
.datepicker-toggle-button{
  display: none;
}
.firefox-only{
  display: none;
}

@-moz-document url-prefix() {
  .firefox-only{
    display: initial;
  }
  .datepicker-toggle {
    display: inline-block !important;
    position: relative;
    width: 100%;
  }
  .datepicker-toggle-button {
    display: block;
    position: absolute;
    right: 1em;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none
  }
}

.react-calendar{
  background-color: $FORM_BACKGROUND_COLOR;
  font-family: 'Montserrat', sans-serif;
  font-size: .8rem;
  width: 33vw;
  max-width: 300px;
  color: $COMMON_TEXT_COLOR;
  &__tile{
    background-color: $FORM_BACKGROUND_COLOR;
    border: none;
    padding: 0.2rem 0;
    width: 25% !important;
    //margin: 0.2rem;
    border-radius: 0.5rem;
    &:hover{
      background-color: rgba($LINK_COLOR, 0.25);
      color: $COMMON_TEXT_COLOR;
    }
    &--active{
      background-color: $LINK_COLOR;
      font-weight: 600;
      color: #fff;
    }
  }
  &__navigation{
    display: flex;
    font-size: 1rem;
    &__arrow, &__label{
      border: none;
    }
    &__arrow{
      padding: 0 0.6rem;
    }
  }
}
.map-export-button {
  background-color: white;
  border: none;
  color: black;
  box-shadow: none;
  background-image: url(/images/export.png);
  background-size: 25px;
  background-repeat: no-repeat;
  width: 25px;
  height: 26px;
}
.map-export-button:disabled {
  opacity: .3;
}
.map-export-input {
  width: 125px;
}
