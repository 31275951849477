.album-element {
    cursor: pointer;
}
.album-element:hover {
    background-color: #f0f0f0;
}
.profile-table td:first-child {
    font-weight: bold;
    padding-right: 10vw;
}
.album-element-bar {
    padding: 0;
}
.album-element-bar .inner-panel {
    padding: .75rem 1.25rem;
    padding-right: 0;
}
.album-element-bar > div:last-child {
    padding-right: 1.25rem;
}
.itemlist-itemlabel {
    font-style: italic;
}
.itemlist-itemlabel-small {
    font-style: italic;
    font-size: smaller;
}
.itemlist-itemlabel-plain {
    font-size: small;
}
.itemlist-itemlabel-plain-small {
    font-size: smaller;
}
.ql-editor{
    min-height:200px;
}
.drag-handle{
    cursor: row-resize;
}