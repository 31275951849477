#gis-page{
    overflow-x: hidden;
    margin-top: 25px;
}

#gis-container{
    position: sticky;
}

@media (max-width: 480px){
    .sidebar-panel{
        width: 300px;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .gis-tab {
        margin-top: calc(526px - 50%);
    }
    #gis{
        height: 80vh;
        width: 95vw;
        margin-left: 5vw;
    }
    #gis-menu{
        margin-left: 2vw;
        margin-right: 3vw;
    }
    .toolkit-item{
        max-width: 50vw;
        margin-left: 6vw;
    }
    .sidebar-panel{
        width: 480px;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .gis-tab {
      margin-top: calc(550px - 40%);
    }
    #gis{
        height: 80vh;
        width: 95vw;
        margin-left: 10vw;
    }
    #gis-menu{
        margin-left: 2vw;
        margin-right: 3vw;
    }
    .toolkit-item{
        max-width: 50vw;
        margin-left: 4vw;
    }
    .sidebar-panel{
        width: 760px;
    }
  }

@media (min-width: 1200px) {
    .gis-tab {
        margin-top: calc(420px - 30%);
    }
    #gis{
        height: 80vh;
        width: 65vw;
    }
    #gis-menu{
        margin-left: 2vw;
        margin-right: 3vw;
    }
    .toolkit-item{
        max-width: 50vw;
    }
    .sidebar-panel{
        width: 960px;
    }
}

.cartogram-row{
    padding: 12px;
}

.cartogram-row-column-1{
    width: 24vw;
} 

.cartogram-row-column-2{
    width: 12vw;
    display: inline-block;
    vertical-align: top;
}

.cartogram-button{
    float: right;
    margin-top: 0.66em;
}

.historical-analysis-container{
    margin-left: 1vw;
    width: 26vw;
    padding: 2px;
    height: 40vh;
}

.historical-analysis-button{
    float: right;
}

.cartogram-search-tools-container{
    margin-left: 25px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.cartogram-search-tool-column{
    padding: 10px;
    margin: 2px;
}

.cartogram-dropdown-collor-button{
    border: solid;
    border-left-width: 2px;
    border-right-width: 2px;
    border-top-width: 2px;
    border-bottom: none;
    border-color: black;
    height: 30px;
}

#historicalMap{
    height: 70vh;
    width: 95vw;
}

@media (max-width: 1100px) {
    #historicalMap{
        height: 75vh;
        width: 100vw;
    }
}

.historical-map-tool{
    margin-left: 3vw;
    margin-top: 3vh;
    margin-bottom: 2vh;
    width: 60vw;
}

.historical-map-text{
    margin-top: 10px;
    margin-bottom: 10px;
}

.grouped-taxon-tool{
    margin-top: 3vh;
    margin-bottom: 3vh;
    margin-left: 1vw;
    margin-right: 1vw;
}

.grouped-taxon-row{
    width: 22vw;
    display: inline-table;
    margin: 10px;
}

.manual-sequence-buttons{
    display: flex;
    align-items: flex-end;
}

.too-many-taxons-container{
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    margin-top: 1rem;
    background-color: rgb(233, 225, 225);
}

.too-many-taxons-info-badge{
    background-color: rgb(236, 140, 29);
    font-size: 3rem;
    padding: 1.5rem;
    margin-right: 1rem;
    padding: 1.5rem;
}

.too-many-taxons-info-text-container{
    display: flex;
    flex-direction: column;
}

@media (max-width: 600px) {
    .too-many-taxons-info-text-container{
        padding: 0.5rem;
    }
    .too-many-taxons-info-badge{
        display: none;
    } 
}

.too-many-taxons-info-text-box{
    padding: 0.2em;
}

.too-many-taxons-close-button{
    color: rgb(255, 255, 255);
    font-size: 2rem;
    margin-left: auto;
}

.circle-picker{
    background-color: #F7F7F7;
    padding-left: 10px;
    padding-top: 10px;
}

.circle-picker div{
    border: 0.1px solid black;
    border-radius: 50%;
}

.color-picker-tip{
    border: 0.1px solid black;   
    padding-left: 20px;
    margin-left: .5rem;
}

#gisMap{
    height: 75vh;
    width: 80vw;
}

@media(max-width: 991px){
    .refresh-map-button{
        position: absolute;
        z-index: 6000;
        margin-top: 1.5em;
        margin-right: 1.5vw;
        right: 0;
    }
}

@media (min-width: 992px){
    .refresh-map-button{
        position: fixed;
        z-index: 6000;
        right: 0;
        margin-right: 60px;
        margin-top: -2em;
    }
}

.range-slider-container{
    margin-left: 10vw;
}

.predefined-layers-container {
    overflow-y: scroll;
    max-height: 500px;
    margin-right: 5px;
}

.play-timelapse-animation-button{
    float: right;
    margin-left: 500px;
    margin-top: -36px;
}

.leaflet-bottom {
    z-index: 900;
}
