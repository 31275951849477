.my-facebook-button-class {
    background-color: rgb(66, 133, 244);
    display: inline-flex;
    align-items: center;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    padding: 0px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
}

.my-facebook-button-class:hover {
    cursor: pointer;
    opacity: 0.9;
}

.my-facebook-button-class:disabled {
    opacity: 0.6;
    cursor: default;
}

.my-facebook-icon-class {
    margin-right: 10px;
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    padding: 10px;
    border-radius: 2px;
}

.my-facebook-text-class {
    padding: 10px 10px 10px 0px; 
    font-weight: 500;
    width: 200px;
}

.my-google-class span {
    width: 200px;
}
