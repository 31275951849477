.sidebar-text{
    flex: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 14px;
    padding-bottom: 14px;
}

@media(max-width: 991px){
    .sidebar-single-button-container{
        padding: 8px 15px 8px 5px;   
    }
    .sidebar-container{
        width: 100vw;
        position: -webkit-sticky;
        position: sticky;
        margin-left: 4vw;
    }
}

@media (min-width: 992px){
    .sidebar-single-button-container{
        padding: 8px 15px 8px 15px;   
    }
    .sidebar-container{
        position: absolute;
        left: 0;
        z-index: 1000;
        background-color: white;
    }
    .sidebar-container-collapsed{
        width: 8vw;
    } 
    .sidebar-container-open{
        width: 70vw;
    }
}

.sidebar-button-collapsed{
    background-color: #2b2b2b;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.sidebar-button-collapsed:hover,
.sidebar-button-collapsed:focus
{
    transform: scale(1.35);
    transition: all ease 500ms;
    background-color: rgb(58, 58, 153);
    outline-width: 0;
}

.sidebar-opened-buttons-container{
    display: flex;
    cursor: pointer;
}

.sidebar-collapsed-buttons-container{
    flex-direction: "column";
}

.sidebar-items-arrow{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    padding: 12px;
}


@media (min-width: 992px){
    .playOpacityAnimation{
        -webkit-animation-duration:3.5s;
        animation-duration:3.5s;
        -webkit-animation-fill-mode:both;
        animation-fill-mode:both
    }

    .changeOpacityAnimation{
        -webkit-animation-name:changeOpacity;
        animation-name:changeOpacity
    }

    @-webkit-keyframes changeOpacity{
        0%{
            opacity: 0;
        }
        100%{
            opacity:1;
        }
    }
    @keyframes changeOpacity{
        0%{
            opacity: 0;
        }
        100%{
            opacity:1;
        }
    }

    .playContentOpacityAnimation{
        -webkit-animation-duration:1.75s;
        animation-duration:1.75s;
    }

    .changeContentOpacityAnimation{
        -webkit-animation-name:changeContentOpacity;
        animation-name:changeContentOpacity
    }

    @-webkit-keyframes changeContentOpacity{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
    @keyframes changeContentOpacity{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .playResizeAnimation{
        -webkit-animation-duration:0.35s;
        animation-duration:0.35s;
        -webkit-animation-fill-mode:both;
        animation-fill-mode:both
    }

    .resizeAnimation{
        -webkit-animation-name:resizeSidebar;
        animation-name:resizeSidebar;
    }

    @-webkit-keyframes resizeSidebar{
        0%{
            width: 0;
        }
        100%{
            width: 70vw;
        }
    }

    @keyframes resizeSidebar{
        0%{
            width: 0;
        }
        100%{
            width: 70vw;
        }
    }
}

.leaflet-top {
    z-index: 800;
}

.visualization-button {
    z-index: 1010;
}

.pseudo-tabs-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 60px;
    background-color: #F7F7F7;
}

.pseudo-tabs-item{
    background-color: #F7F7F7;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    color: #495057;
    display: block;
    margin-bottom: -1px;
    border: none;
    &:focus{
        box-shadow: none;
    }
}

.pseudo-tabs-item-active {
    background-color: white !important;
    color: #495057;
    font-weight: 600 !important;
}
