$LINK_COLOR: #0056B3;
$COMMON_TEXT_COLOR: #262626;

.btn-primary, .badge-primary, .nav-pills .nav-link.active, .page-item.active .page-link {
    background-color: #0056B3;
    border-color: #0056B3;
}
.btn-primary:disabled {
    background-color: #0051A8;
    border-color: #0051A8;
}

.page-item.active .page-link {
    border-color: #0056B3;
}

.btn-link, a, .page-link {
    color: #0056B3;
}

.home-header .title {
    color: #595959;
}

.leaflet-container a.leaflet-popup-close-button {
    color: #454545;
}

.main-content-container h1 {
    font-size: 1.75rem;
}

.main-content-container h2 {
    font-size: 1.4rem;
}

.main-content-container h3, .map-type-title{
    font-size: 1.25rem;
}

.map-type-title{
    margin: 0;
    padding: 0;
    font-weight: 500;
}

.main-content-container .name h1 {
    font-size: 1rem;
}

.my-facebook-button-class {
    background-color: #0056B3;
}

.my-google-class {
    background-color: red;
}

a:not(.passive-header):not(.active):not(.nav-link), .pseudo-tabs-item:not(.pseudo-tabs-item-active), 
.sidebar-opened-buttons-container, .nav-tabs .nav-link .btn-secondary:not(.active){
    &:hover, &:focus{
    text-decoration: underline solid $LINK_COLOR;
    color: $LINK_COLOR;
    }
}

.nav-item.active:hover a{
    text-decoration: none;
    &:hover, &:focus{
    text-decoration: none !important;
    }
} 

.nav-item{
    border-bottom: 2px solid transparent;
    text-align: center;
    &:hover, &:focus{
        border-bottom: 2px solid $LINK_COLOR;
    }
}

.app-menu .nav-item .btn-secondary:focus{
    border-bottom: 2px solid $LINK_COLOR !important;
}

@media (min-width: 1200px){
    .second-row-menu .nav-item{
        .dropdown{
            display: flex;
        }
    }
}

.dropdown-item:not(.passive-header), .dropdown-toggle.btn, 
.app-menu .nav-item .btn-secondary,
.app-menu .navbar-light .navbar-nav .nav-link{
     &:focus{
        outline: none;
    }
}

.nav-tabs .nav-link:not(.active),
.pseudo-tabs-item:not(.pseudo-tabs-item-active){
    color: $LINK_COLOR;
    &:hover, :focus{
        text-decoration: underline solid $LINK_COLOR;
        color: $LINK_COLOR;
    }
}

.nav-tabs, .pseudo-tabs-container{
    border-bottom: 1px solid $COMMON_TEXT_COLOR;
}

.nav-tabs .nav-link {
    border: 1px solid #ced4da;
    border-bottom: 1px solid $COMMON_TEXT_COLOR;
    &.active{
        font-weight: 700;
        border: 1px solid $COMMON_TEXT_COLOR;
        border-bottom: none;
    }
    &:not(.active){
        font-weight: 500;
        &:hover{
            border: 1px solid #ced4da;
            border-bottom: 1px solid $COMMON_TEXT_COLOR;
        }
    }
    
}

.pseudo-tabs-item{
    border: 1px solid #ced4da;
    border-bottom: 1px solid $COMMON_TEXT_COLOR;
    &-active, &-active:hover{
        font-weight: 700;
        border: 1px solid $COMMON_TEXT_COLOR;
        border-bottom: none;
    }
    &:not(.pseudo-tabs-item-active){
        font-weight: 500;
        &:hover{
            border: 1px solid #ced4da;
            border-bottom: 1px solid $COMMON_TEXT_COLOR;
        }
    }
}
