#root {
  height: 100vh;
}

$MENU_TOP_MARGIN: 122px;

%common-part {
    font-size: 10px;
}

.test-red-font-10px {
    @extend %common-part;
    color: red;
}

.main-content-container {
    margin-top: 122px;
}

#map{
    height: 90vh;
}

#map-area-search{
    height: 60vh;
}

.accordion .card {
    overflow: visible;
}

.leaflet-loader-container {
	height: 100%;
	width: 100%;
	
	position: absolute;
	z-index: 900;

	cursor: auto;
}
.leaflet-loader-container .leaflet-loader-background {
    height: 100%;
    width: 100%;
    position: absolute;
	background-color: rgba(0,0,0,0.9);
}

.leaflet-loader {
	width:57.6px;
	margin: 30em auto;

}

@-webkit-keyframes hideLoader {
    from {opacity: 1;}
    to {opacity: 0;}
}
@keyframes hideLoader {
    from {opacity: 1;}
    to {opacity: 0;}
}

/*
Following css for the loading animations comes from :
https://connoratherton.com/loaders
*/

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }

  45% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0.7; }

  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }
@keyframes scale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }

  45% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0.7; }

  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

.leaflet-loader > div:nth-child(0) {
  -webkit-animation: scale 0.75s -0.36s infinite cubic-bezier(.2, .68, .18, 1.08);
          animation: scale 0.75s -0.36s infinite cubic-bezier(.2, .68, .18, 1.08); }
.leaflet-loader > div:nth-child(1) {
  -webkit-animation: scale 0.75s -0.24s infinite cubic-bezier(.2, .68, .18, 1.08);
          animation: scale 0.75s -0.24s infinite cubic-bezier(.2, .68, .18, 1.08); }
.leaflet-loader > div:nth-child(2) {
  -webkit-animation: scale 0.75s -0.12s infinite cubic-bezier(.2, .68, .18, 1.08);
          animation: scale 0.75s -0.12s infinite cubic-bezier(.2, .68, .18, 1.08); }
.leaflet-loader > div:nth-child(3) {
  -webkit-animation: scale 0.75s 0s infinite cubic-bezier(.2, .68, .18, 1.08);
          animation: scale 0.75s 0s infinite cubic-bezier(.2, .68, .18, 1.08); }
.leaflet-loader > div {
  background-color: rgb(241, 237, 237);
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: inline-block; }

.atpol-popup .leaflet-popup-content-wrapper {
    background: #b4b9bd;
    opacity: 80%;
    color:#013d75;
    font-size: 40px;
}

.atpol-popup .leaflet-popup-tip {
    border-bottom:180px solid;
}

.atpol-popup-detailed .leaflet-popup-content-wrapper {
    background: #b4b9bd;
    opacity: 80%;
    color:#013d75;
    font-size: 20px;
}

.atpol-popup-detailed .leaflet-popup-tip {
    border-bottom:180px solid;
}

.taxondb-location-map {
    height: 90vh;
}

.openseadragon-container {
    width: 100%;
    height: 70vh;
}

.openseadragon-ratio-container {
    position: relative;

    >.openseadragon-container {
        position: absolute !important;
    }
}

.home-header {
    font-family: Segoe UI;
    text-align: center;
}
.home-header .title {
    color: #777;
    font-size: 18px;
    letter-spacing: 4px;
}
.home-header .title .name {
    margin-top: 30px;
    font-size: 36px;
}
.home-header img {
    padding-bottom: 10px;
}
.home-header .content {
    text-align: left;
    padding: 20px 150px;
}
@media (max-width: 1200px) {
.hide-on-mobile {
    display: none;
}
.home-header .content {
    text-align: left;
    padding: 20px 50px;
}
}

.taxondb-footer img {
    padding: 10px;
}

.taxondb-footer .info {
    padding: 50px 5px;
}

%spinner-inside {
    position: absolute;
    top: 11px;
}

.spinner-inside-input {
    @extend %spinner-inside;
    right: 10px;
}
.spinner-inside-combo {
    @extend %spinner-inside;
    right: 20px;
}

.info-icon {
    cursor: pointer;
}

.popover {
    max-width: 70vw;
    padding: .50rem;
}

$COMMON_BACKGROUND_COLOR: #f0f0f0;
$COMMON_GRAY_BACKGROUND_COLOR: #d0d0d0;
$COMMON_DARK_BACKGROUND_COLOR: #c0c0c0;

%common-background {
    background-color: $COMMON_BACKGROUND_COLOR;
}

.common-panel-background {
    @extend %common-background;
}

.dynamic-form {
    @extend %common-background;
}

.dropdown-menu {
    z-index: 1040;
}

// dynamic panels /////////////////////////////////////////////
.dynamic-logic-panel {
    border: 1px solid $COMMON_DARK_BACKGROUND_COLOR;
    margin-top: .25rem;
    margin-bottom: .15rem;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.main-dynamic-logic-panel, .main-dynamic-logic-panel2 {
    border-top: none;
    border-left: none;
    border-right: none;
}

.dynamic-logic-panel h2 {
    margin: 0;
}

.dynamic-logic-panel .form-group {
    margin: .25rem 0;
}

.dynamic-logic-panel .form-group .form-label {
    margin-right: .25rem;
}

.dynamic-form-content > .dynamic-logic-panel {
    margin: 0;
}

.dynamic-form-content select, .dynamic-form-content input {
    max-width: 15rem;
    min-width: 15rem;
}

.no-radius-left, .no-radius-left input, .no-radius-left select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.no-radius-right, .no-radius-right input, .no-radius-right select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.panel-trash:hover {
    background-color: $COMMON_GRAY_BACKGROUND_COLOR
}

.panel-trash:hover ~ .panel-brackets{
    background-color: $COMMON_GRAY_BACKGROUND_COLOR
}

.panel-trash:hover ~ .panel-inner{
    background-color: $COMMON_GRAY_BACKGROUND_COLOR
}

.panel-trash {
    order: +1;
}

.panel-trash .btn {
    margin: .25rem;
}


.panel-brackets {
    padding: 0 1rem;
}

.panel-inner {
    padding: .5rem 0;
}

.panel-content {
    flex-basis: 100%;
}

.main-dynamic-logic-panel > .panel-content {
    padding-left: 15px;
    padding-right: 15px;
}

.main-dynamic-logic-panel2 > .panel-content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: .5rem;
}

.dynamic-logic-panel .row {
    margin-left: 0;
    margin-right: 0;
}

.panel-border-bottom {
    border-bottom: 1px solid $COMMON_DARK_BACKGROUND_COLOR;
}
////////////////////////////////////////////////////////////////

.nav-tabs .nav-link.active {
    background-color: blanchedalmond;
}

.table tr:first-child td {
    border-top: none;
}

.icondb-thumbnail {
    border: 3px solid white;
    color: black;
}
.icondb-thumbnail:hover {
    border-color: blue;
    text-decoration: none;
}

.icondb-thumbnail.active {
    background-color: blanchedalmond;
}

.icondb-openseadragon-container {
    width: 100%;
    height: 50vh;
}

.icondb-comparator-openseadragon-container {
    width: 94%;
    height: 80vh;
}

.icondb-right-panel {
    @extend %common-background;
    height: calc(100vh - 122px);
    overflow-y: scroll;
    top: $MENU_TOP_MARGIN;
}

@media (max-width: 800px) {
    .icondb-right-panel {
        height: 100%;
        overflow: hidden;
    }
}

.admin-tools-item-row {
    word-wrap: break-word;
    overflow: auto;
    padding: 0.5rem;
}

.chart-container {
    margin-bottom: 2rem;
    background-color:#e9ecef;
    border-radius: .3rem;
}

.chart-form-container {
    background-color:#e9ecef;
    padding: 12px;
    border-radius: .5rem;
}

.pagination {
    margin-bottom: 0;
}

.button-cursor:not(:disabled) {
    cursor: pointer;
}

.navbar a {
    white-space: nowrap;
}

.cookies-info {
    background-color: blanchedalmond;
}

.report-table tr:nth-child(even) {
    background-color: blanchedalmond;
}
.report-table td, .report-table th {
    padding: .5rem;
}
.report-table thead tr:first-child {
    border-bottom: 1px solid $COMMON_GRAY_BACKGROUND_COLOR;
    background-color: $COMMON_BACKGROUND_COLOR;
}
.report-table {
    border: 1px solid $COMMON_GRAY_BACKGROUND_COLOR;
}

.language-polish .ql-snow .ql-tooltip[data-mode=link]::before {
    content: "Wprowadź link:";
}

.language-polish .ql-snow .ql-tooltip::before {
    content: "Odwiedź URL:";
}

.language-polish .ql-snow .ql-tooltip a.ql-action::after {
    content: 'Edytuj';
}

.language-polish .ql-snow .ql-tooltip a.ql-remove::before {
    content: 'Usuń';
}

.language-polish .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    content: 'Zapisz';
}

.player-wrapper {
  position: relative;
  padding-top: 30px;
}

.revision-table-wrapper {
    overflow-x: auto;
}

.revisions-lower-taxons-row{
    display: flex;     
}

.revision-lower-taxon-item{
    margin-right: 0.5em;
    margin-left: 0.5em;
}

.revision-form{
    min-width: 6em;
}

.revisions-row{
    justify-content: flex-start;
    display: flex; 
}

.markerIconLegendSwitch{
    display: flex;
    justify-content: flex-end;
}

.form-inline-wrapper{
    display: inline-block;
    margin-top: 8px;
}
// quick fix for bootstrap validation markers for selects
select.form-control.is-valid,
select.form-control.is-invalid {
  background-position: right calc(0.875em + 0.1875rem) center;
}

.external-link-popover {
    a {
        svg {
            margin-left: 10px;
            margin-bottom: 3px;
            height: 16px;
        }
    }
}
